
$font-text: 14px;
$font-questions: 18px;
#content-rates-creation {
    margin-left: 70px;
    margin-top: 47px;
    overflow: visible;
    padding-bottom: 30px;
    min-height: 100%;
    padding: 40px 25px 25px 25px;

    .mt-1 {
        margin-top: 10px;
    }
    .mb-1 {
        margin-bottom: 10px !important;
    }
    .ml-1 {
        margin-left: 10px !important;
    }
    .mr-1 {
        margin-right: 10px;
    }

    h1 {
        color: $primary;
        margin: 10px 0;
    }
    .card {
        padding: 20px;
        border-radius: .5rem;
        margin-top: 35px;
        background-color: $bg-primary;

        span {
            font-size: $font-text;
        }
        

        .aviso-rateplans{
            font-size: $font-text;
            padding-right: 30px;
            background-color:#CEDDF7;

            .title-warning {
                color: $secondary-700;
            }
        }

        .ivu-form-item{
            margin-bottom: 0;
        }
        .margin-row {
            margin: 15px 0 40px 0;
        }

        ul{
            list-style: none;
        }

        .ivu-input-number {
            width: 65px;
        }
        .btn-remove-bed {
            padding: 0 0 7px 0;
            border: none !important;
            background-color: transparent !important;
        }
        .ivu-btn-icon-only {
            font-size: 20px !important;
        }

        .ivu-btn-dashed:hover{
            color: $primary;
            border-color: $primary;
        }


        // .ivu-steps{
        //     margin-top: 30px;
        //     span {
        //         font-size: @font-text;
        //     }
        //     .ivu-steps-head {
        //         background: none; 
        //         .ivu-steps-icon {
        //             font-size: 24px;
        //         }
        //     }
        //     .ivu-steps-title {
        //         font-size: @font-questions;
        //         background: none;
        //     }
        //     .ivu-select-visible {
        //         overflow: visible;
        //     }
        //     .ivu-steps-main {
        //         min-height: 30vh;
        //         margin: 0;
        //         // overflow: visible;
        //         display: block;

        //         .margin-row {
        //             margin: 15px 0 40px 0;
        //         }
        //     }
        // }
        
    }

    .container-btn{
        margin-top: 30px;
        .activateRatePlan-btn {
            .ivu-form-item-content{
                margin-left: 0 !important;
            }
    
            .btn-acctivateRP{
                width: 100%;
                border-radius: 1rem;
                font-size: $font-text;
                background-color: $primary;
                border-color: $primary;
            }
    
            .btn-acctivateRP:focus{
                box-shadow: 0 0 0 2px rgba(168, 45, 240, 0.2);
            }

        }
        .reset-btn{
            // margin-top: 10px;
            // margin-left: 14vw !important;
            border: none;
        }
        .reset-btn:focus{
            box-shadow: none !important;
        }
    }

    .sticky-top {
        position: sticky;
        top:100px;
        .ivu-table-row{

            td.name-attr.table-attr-column{
                .ivu-table-cell{
                    padding-right: 0;
                }
            }
        }
        .ivu-table-header {
            display: none;
        }
        .ivu-table .name-attr {
            font-weight: bold;
            
        }
        .ivu-table .is-setted {
                color: rgb(65, 7, 87);
        }
        .ivu-table .no-setted {
            font-style: italic;
            color: #AEB1C2;
        }
        .ivu-table-cell{
            span{
                word-break: normal;
            }
        }
    }
    @media only screen and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : portrait) {
            .detalle-rate{
                padding-top: 70px;
            }
        }

    .ivu-message {
        top: 300px !important;
        z-index: 3000 !important;
    }
}
