.drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}

.drawer-detail {
  font-size: 14px;
}

.drawer-title {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  display: block;
  margin-bottom: 16px;
}

.drawer-subtitle {
  font-size: 15px !important;
  color: rgba(0, 0, 0, 0.80);
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
}

.striped_bg {
  background-color: #f8f8f9;
}
