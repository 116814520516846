.sidebar {
    min-height: 100vh;
    overflow:hidden;
    background: linear-gradient(to bottom, rgba(136,152,221,1) 0%, rgba(168,155,237,1) 60%, rgba(183,121,245,.9) 100%);

    &.menu-collapsed {
        #getabed-logotipo {
            width: 0px;
            transition: width .2s ease;
        }

        span {
            width: 0px;
            transition: width .2s ease;
        }

        .ivu-menu-submenu-title-icon {
            display:none;
        }
    }

    .gab-holder {
        height:80px;
        display:flex;
        align-items:center;
        justify-content:center;
        img {
            margin:2px;
        }
    }
    
    .sidebar-menu {
        span {
            display:inline-block;
            overflow:hidden;
            white-space: nowrap;
        }
        .sidebar-menu-item {
            &.ivu-menu-item-active {
                color: $active-fg;
                background: $active-bg;
            }
        }

        .ivu-menu-opened .ivu-menu-submenu-title {
            color: $subactive-fg;
            background: $subactive-bg;
        }

        .sidebar-menu-item, .ivu-menu-submenu-title {
            background: $normal-bg;
            display:flex;
            align-items:center;
        }
        
        .ivu-menu-submenu .sidebar-menu-item,
        .sidebar-menu-item,
        .ivu-menu-submenu-title {
            &:hover {
                color: $active-fg;
                background: $active-bg;
            }
        }
    }

    .ivu-layout-sider-trigger {
        background-color:transparent !important;
    }
}

.header {
    background-image: linear-gradient(180deg, #2E3142 0%, #5C6284 100%);
    padding:0;

    .header-menu {
        background:transparent;
        display:flex;
        justify-content: flex-end;

        .header-menu-item {
            background:transparent;

            &:hover .ivu-menu-submenu-title-icon {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }
}

.main-content {
    padding:25px;
}