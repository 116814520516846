body.login {
    aside {
        background: linear-gradient(to bottom, rgba(136,152,221,1) 0%, rgba(168,155,237,1) 60%, rgba(183,121,245,.9) 100%);
        @extend .col-md-4;
        @extend .d-none;
        @extend .d-md-flex;
        @extend .vh-100;
        @extend .flex-column;

        .gab-holder {
            height:120px;
            display:flex;
            align-items:center;
            justify-content:center;
            img {
                margin:2px;
            }
        }

        color: white;
        a {
            color: white;
            text-decoration: underline;
            @extend .text-nowrap;
        }

        .pie {
            @extend .mt-auto;
            @extend .mb-2;

            .col {
                @extend .text-center;
            }
        }
    }
    section {
        @extend .col-md-8;
        @extend .col-12;
        @extend .vh-100;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
    }
}