#rateplans-calendar{
    .ivu-card {
        .ivu-card-head {
            background: $secondary-700;
            color: $bg-primary!important;
            border-top-right-radius:  .5rem;
            border-top-left-radius:  .5rem;
            p{
                color: $bg-primary!important;
                font-size:18px;
            }
        }
        .ivu-card-body{
          padding: 0px!important;
        }
    }
    .weekday-label{
        background: $bg-primary;
    }
    .vuecal__event {
        color: #666 !important;
        height: 45px !important;
        max-height: 45px !important;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        background-color: #fff!important;
    }
    .vuecal__event.bg-primary{
        background: $bg-primary!important;
    }
    .vuecal__event.precio_base {
        height: 39px !important;
        background: $bg-primary!important;
    }
    .vuecal__cell{
        border-left: 1px solid #ccc;
    }

}
