.color-primary{
    color: $primary!important;
}
.bg-primary{
background: $primary!important;
color: $bg-primary;
}
.color-secondary{
color: $secondary!important;
}
.bg-secondary{
background: $secondary!important;
}
.title-rate{
    color:#8F99E1;
}
    .ivu-form-item{

        .ivu-form-item-content{
            margin-left: 0px !important;
        }
    }

    .field {
        font-size: 14px;
        font-weight: bolder;
    }
    .text-detail {
        font-size: 12px;
    }
    .text-info-input{
        font-size: 11px;
        font-style: italic;
        color: #424860;
    }

    .form-btn{
        width: 80px;
    }
    .btn-save{
        color: #fff;
        background-color: $normal-bg;
        background-color: $normal-bg;
    }
    .btn-save:hover {
        color: #fff;
        border-color: $primary;
        background-color: $subactive-bg;
        // 
    }
    .btn-save.active, .btn-save:active {
        color: #f2f2f2;
        background-color: $normal-bg;
        background-color: $normal-bg;
    }
    .btn-cancel:hover {
        color: $normal-bg;
        background-color: #fff;
        border-color: $normal-bg;
    }

    li {
        list-style: none;
    }

    .ivu-drawer-header-inner{
        font-weight: 500;
    }

    .detalle-rate{
        p {
            font-size: 14px !important;
            font-weight: bold;
            color: grey;
            margin-left: 5px;
        }
        i {
            color: rgb(174, 174, 250);
        }
    }

    .btn-edit-datail{
        width: 120px;
        background-color: $normal-bg;
        border: $normal-bg;
    }

    .btn-edit-datail:hover{
        background-color: $subactive-bg;
        border-color: $normal-bg;
    }

    .btn-delete-datail:hover{
        border-color: $normal-bg;
        color: $normal-bg;
    }

    .title-rate-edit{
        color: #8F99E1;
        font-size: 14px;
        font-style: italic;
    }
    .show-edit-options {
        color: rgb(174, 174, 250);
        margin-left: 60px;
    }
    .show-edit-options:hover {
        color: $normal-bg;
    }