$font-family-base: "Helvetica Neue",Helvetica,Arial,sans-serif;
$font-weight-base: normal;
$font-size-base: 0.9rem;
$h1-font-size:                $font-size-base * 3 !default;
$h2-font-size:                $font-size-base * 2.5 !default;
$h3-font-size:                $font-size-base * 2 !default;
$h4-font-size:                $font-size-base * 1.75 !default;
$h5-font-size:                $font-size-base * 1.5 !default;
$h6-font-size:                $font-size-base * 1.25 !default;


$normal-bg: rgb(155, 138, 230);
$active-bg: rgb(99, 88, 156) !important;
$active-fg: rgba(255,255,255,.7);
$subactive-bg: rgb(125, 110, 189) !important;
$subactive-fg: rgba(255,255,255,.7) !important;

$primary: #B679F5;
$secondary: #4071CE;
$secondary-700: #648BCE;
$bg-primary: #F6F6FA;
// Base
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/utilities";

@import "../../node_modules/iview/dist/styles/iview.css";

@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/light";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/duotone";

@import "../../node_modules/vue-cal/dist/vuecal.css";

// Secciones
@import "sections/_defaults";
@import "sections/_login";
@import "sections/_rateplans_calendar";
@import "sections/_detailrp_edit";
@import "sections/_sales";
@import "sections/_rateplans_create";